
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";
import IRegiao from "@/lib/interfaces/regiao";
import ISection from "@/lib/interfaces/section";

export default Vue.extend({
  name: "RegionsList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    region: {
      type: Object as PropType<IRegiao>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      regions: new Array<IRegiao>(),
      sections: new Array<ISection>(),

      headers: [
        { text: "Setor", value: "cSection", align: "left" },
        { text: "Razão social", value: "nome_raz", align: "left" },
        { text: "Nome fantasia", value: "apelido_fant", align: "left" },
        { text: "CNPJ", value: "cpf_cnpj", align: "right" },
        { text: "Inscrição estadual", value: "doc_ie", align: "right" },
        {
          text: "Informações adicionais",
          value: "inf_adicional",
          align: "right",
        },
        { text: "ndias", value: "corpo.ndias", align: "right" },
        { text: "max_vendedor", value: "corpo.max_vendedor", align: "right" },
        {
          text: "dias_lancamento",
          value: "corpo.dias_lancamento",
          align: "right",
        },
        {
          text: "dias_relatorios",
          value: "corpo.dias_relatorios",
          align: "right",
        },
        {
          text: "Status",
          value: "cDesativado",
          sortable: false,
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
    };
  },

  computed: {
    cRegions(): Record<string, unknown>[] {
      const regions =
        this.desativado === ""
          ? this.regions
          : this.regions.filter(
              (region) => region.desativado === this.desativado
            );

      return regions.map((region) => ({
        ...region,
        cSection:
          this.sections.find((section) => section.id === region.id_setor)
            ?.nome ?? region.id_setor,
        cDesativado: region.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  watch: {
    forceRefresh() {
      this.getRegions().then();
    },

    options: {
      deep: true,
      handler() {
        this.getRegions().then();
      },
    },
  },

  mounted() {
    this.getRegions().then();
    this.getSections().then();
  },

  methods: {
    async openRegion(id_setor: string, id_pessoa_empresa_parametros: string) {
      const region = this.regions.find(
        (region) =>
          region.id_setor == id_setor &&
          region.id_pessoa_empresa_parametros === id_pessoa_empresa_parametros
      );

      this.$emit("update:region", { ...region, corpo: { ...region?.corpo } });
    },

    async getRegions() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("regiao.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const regions = rsp.getTable("regiao")?.getRowsObject() ?? [];
        this.regions = regions.map((region) => ({
          ...region,
          corpo: JSON.parse(region.corpo),
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getSections() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("section.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.sections = rsp.getTable("setor")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
