
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Row, Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import { required, maxLength } from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ACard from "@/components/elements/a-card.vue";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import IRegiao from "@/lib/interfaces/regiao";
import ATextFieldInteger from "@/components/elements/a-text-field-integer.vue";
import ISection from "@/lib/interfaces/section";
import ATextFieldCnpj from "@/components/elements/a-text-field-cnpj.vue";
import ATextarea from "@/components/elements/a-textarea.vue";

export default Vue.extend({
  name: "RegionsMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ACard,
    AAutocomplete,
    ATextFieldInteger,
    ATextFieldCnpj,
    ATextarea,
  },

  props: {
    region: {
      type: Object as PropType<IRegiao>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataRegion: {
        id_setor: "",
        id_pessoa_empresa_parametros: "default",
        nome_raz: "",
        apelido_fant: "",
        cpf_cnpj: "",
        doc_ie: "",
        inf_adicional: "",
        desativado: "0",
        corpo: {
          ndias: "",
          max_vendedor: "",
          dias_lancamento: "",
          dias_relatorios: "",
        },
      } as IRegiao,

      sections: new Array<ISection>(),
    };
  },

  watch: {
    region: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataRegion = {
          ...this.region,
          corpo: { ...this.region.corpo },
        };
      },
    },
  },

  computed: {
    cTextSave(): string {
      return this.dataRegion.id_pessoa_empresa_parametros === "default"
        ? "Salvar"
        : "Alterar";
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },
  },

  mounted() {
    this.getSections().then();
  },

  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("regiao.write");
        const tblRegiao = new Table("regiao");

        tblRegiao.addCols(
          "id_setor",
          "id_pessoa_empresa_parametros",
          "nome_raz",
          "apelido_fant",
          "cpf_cnpj",
          "doc_ie",
          "inf_adicional",
          "desativado"
        );

        const row = new Row(
          { ...this.dataRegion.corpo },
          this.dataRegion.id_setor,
          this.dataRegion.id_pessoa_empresa_parametros,
          this.dataRegion.nome_raz,
          this.dataRegion.apelido_fant,
          this.dataRegion.cpf_cnpj,
          this.dataRegion.doc_ie,
          this.dataRegion.inf_adicional,
          this.dataRegion.desativado
        );

        tblRegiao.addRows(row);
        rq.addTable(tblRegiao);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma região com essa pessoa";

          return;
        } else {
          if (this.dataRegion.id_pessoa_empresa_parametros === "default")
            this.ret_msg = "Região cadastrada com sucesso";
          else this.ret_msg = "Região atualizada com sucesso";
        }

        this.dataRegion.id_pessoa_empresa_parametros = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataRegion = {
        id_setor: "",
        id_pessoa_empresa_parametros: "default",
        nome_raz: "",
        apelido_fant: "",
        cpf_cnpj: "",
        doc_ie: "",
        inf_adicional: "",
        desativado: "0",
        corpo: {
          ndias: "",
          max_vendedor: "",
          dias_lancamento: "",
          dias_relatorios: "",
        },
      };

      this.form.resetValidation();
    },

    async getSections() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("section.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.sections = rsp.getTable("setor")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
