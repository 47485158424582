
import VTextField from "@/lib/types/v-text-field";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ATextarea",

  props: {
    appendIcon: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    autofocus: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      default: undefined,
    },

    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    label: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    prefix: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    rules: {
      type: Array as PropType<Array<boolean | string>>,
      default: () => [],
    },

    type: {
      type: String as PropType<string>,
      default: "text",
    },

    value: {
      type: String as PropType<string>,
      default: "",
    },
  },

  computed: {
    _vTextField(): VTextField {
      return this.$refs["v-text-field"] as VTextField;
    },

    _input(): HTMLElement {
      return this._vTextField.$refs["input"] as HTMLElement;
    },
  },

  methods: {
    focus() {
      this._input.focus();
    },
  },
});
