
import Vue from "vue";
import RegionsList from "@/components/subviews/regions/regions-list.vue";
import RegionsMaintenance from "@/components/subviews/regions/regions-maintenance.vue";
import IRegiao from "@/lib/interfaces/regiao";

export default Vue.extend({
  name: "Regions",

  components: { RegionsList, RegionsMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      region: {} as IRegiao,
    };
  },
});
