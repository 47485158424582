
import Vue, { PropType } from "vue";
import aTextField from "./a-text-field.vue";

export default Vue.extend({
  name: "ATextFieldInteger",

  components: { aTextField },

  props: {
    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    value: {
      type: String as PropType<string>,
      default: "",
    },

    label: {
      type: String as PropType<string>,
      required: true,
    },

    rules: {
      type: Array as PropType<Array<(value: string) => string | boolean>>,
      default: () => [],
    },
  },

  computed: {},

  watch: {},

  methods: {
    filter(event: KeyboardEvent) {
      if (/^[0-9]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
  },
});
